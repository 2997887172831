var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticClass: "w-4",
      attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "1 0 16 17" },
    },
    [
      _c("title", [_vm._v("User")]),
      _c("g", { attrs: { id: "Capa_2", "data-name": "Capa 2" } }, [
        _c("g", { attrs: { id: "Capa_1-2", "data-name": "Capa 1" } }, [
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M16,15.76a3.34,3.34,0,0,0-2.36-2.58,10.88,10.88,0,0,\n        1-2-.87,7.15,7.15,0,0,1-2.63.62,6,6,0,0,1-2.52-.63,7.12,\n        7.12,0,0,1-1,.5c-.36.14-.73.25-1.1.39A3.35,3.35,0,0,0,2,\n        15.8a.46.46,0,0,0,.5.6h13C15.9,16.4,16.07,16.19,16,15.76Z",
            },
          }),
          _c("path", {
            staticClass: "fill-current",
            attrs: {
              d: "M5.53,8.11A2.12,2.12,0,0,0,6,8.59a.28.28,0,0,1,.16.23,\n        2.48,2.48,0,0,0,.71,1.55.35.35,0,0,1,.07.2c0,.29,0,.57,0,\n        .86a5.08,5.08,0,0,0,1.95.45,6,6,0,0,0,2.12-.48c0-.06,0-.13,\n        0-.21v-.27c0-.64,0-.41.26-.69a3.11,3.11,0,0,0,.48-1.31c0-.2,\n        0-.22.18-.35a1.27,1.27,0,0,0,.36-1.83c-.13-.16,0-.16.08-.46a3.83,\n        3.83,0,0,0,0-2.48,2.74,2.74,0,0,0-2.5-2.15,3.44,3.44,0,0,0-2.37.4,\n        1.65,1.65,0,0,1-.62.31A1.94,1.94,0,0,0,5.42,4.2a5.31,5.31,0,0,0,\n        .29,2.17.37.37,0,0,1-.06.35A1.18,1.18,0,0,0,5.53,8.11Z",
            },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }